@import url('https://cdn.syncfusion.com/ej2/material.css');

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media  screen and (max-width:800px) {
  .sidebar{
    z-index: 10000000;
  }
}

.e-dlg-center-center, .e-quick-popup-wrapper.e-device{
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

 #preview {
  background: transparent
    url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png')
    no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left), .e-input-group.e-success:not(.e-float-icon-left), .e-input-group.e-warning:not(.e-float-icon-left), .e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left){
  border: none;
}

/* Pagination */

  .pagination-container {
    font-family: monospace;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .pagination-container .pagination-item {
    background: #CCC;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
  }

  .pagination-container .active-item {
    background: rgb(177, 139, 139);
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }

  .pagination-container .previous, .pagination-container .next {
    color: #333;
    border-radius: 50%;
    font-weight: bold;
    font-size: 1.2rem;
    width: 27px;
    height: 27px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
  }

  .blue-theme .active-item {
    background: #1A97F5;
  }
  .green-theme .active-item {
    background: #03C9D7;
  }
  .purple-theme .active-item {
    background: #7352FF;
  }
  .red-theme .active-item {
    background: #FF5C8E;
  }
  .indigo-theme .active-item {
    background: #1E4DB7;
  }
  .orange-theme .active-item {
    background: #FB9678;
  }

  #kanban.e-kanban {
    background: transparent;
  }

  .MuiAutocomplete-input {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    background-color: rgb(255 255 255);
    border-radius: 0.375rem;
  }

  .MuiAutocomplete-input:focus {
    outline: 0;
  }

  .e-kanban .e-kanban-table .e-header-cells .e-column-expand:hover, .e-kanban .e-kanban-table .e-header-cells .e-column-expand:focus, .e-kanban .e-kanban-table .e-header-cells .e-column-collapse:hover, .e-kanban .e-kanban-table .e-header-cells .e-column-collapse:focus {
    background: transparent;
  }

  .MuiAutocomplete-input {
    width: 100%;
  }

.table_container, .myLoader, .projects_container {
  min-height: 450px !important;
}

@media(max-width: 500px) {
  .table_container, .myLoader, .projects_container {
    min-height: 300px !important;
  }
}

.confimrModalContent {
  width: 100%;
  max-height: 500px !important;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgba(210, 231, 230, 0.8);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(210, 231, 230, 0.9);
}

.print {
  display: none !important;
}

@media print {
  .noprint {
    display: none;
  }

  .print {
    display: block !important;
  }

  .print-container {
    -webkit-print-color-adjust: exact;
    width: 100%;
    height: 100%;
    /* position: absolute; */
    background-color: #fff;
    position: fixed;
    inset: 0;
    /* top: 0; */
    /* left: 0;  */
    z-index: 9999;
    /* page-break-before: auto; */
  }
}

.e-kanban .e-kanban-table.e-content-table .e-content-row:not(.e-swimlane-row) td {
  background-color: transparent;
  border: none;
}

#customer-auto, #supplier-auto, input[name=product-auto] {
  background-color: #f1f5f9;
  border-radius: 0;
}

.home-block {
  height: 33rem;
}

@media(max-width: 1500px) {
  .home-block {
    height: 24rem;
  }
}

@media(max-width: 500px) {
  .home-block {
    height: auto;
  }
}

.home-block canvas {
  width: 100% !important;
}